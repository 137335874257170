import React, { useEffect, useState } from 'react';

import { LoadingRound } from '../components/common/loading';
import { Img, ImgBannerContainer } from '../components/common/img';
import { Layout } from '../components/layout/layout';

import { url } from '../data/constant';
import { formatDate } from '../utils';

import ImgBanner from '../images/banner-publicacion.png';
import './css/postPage.css';

const INITIAL_POST = {
  clubId: '',
  date: '',
  description: '',
  title: '',
  img: '',
};

const PostPage = ({ location, pageContext }) => {
  const params = new URLSearchParams(location.search);
  const postId = params.get('postId');

  const { club, menus } = pageContext;
  const { facebookLink, twitterLink, instagramLink, contact = {} } = club;

  const [post, setPost] = useState({ ...INITIAL_POST });
  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    if (!postId) return;

    setLoading(true);

    try {
      const request = await fetch(`${url}/post/${postId}`);

      if (request.ok) {
        const posts = await request.json();

        setPost(posts);
      }
    } catch (_) {
      setPost({ ...post });
    }

    setLoading(false);
  }, []);

  function format(date) {
    return formatDate({ date, isoFormat: 'dd {} MMMM, yyy' }).replace('{}', 'de');
  }

  function renderContent() {
    if (isLoading) {
      return (
        <div className="flex center">
          <LoadingRound />
        </div>
      );
    }

    return (
      <>
        <div className="row flex">
          <span className="post-date">{format(+post.date)}</span>

          <span className="text-blue font-bold p-v-16 font-25">{post.title}</span>

          <p className="font-light font-18">{post.description}</p>
        </div>
      </>
    );
  }

  function renderNoContent() {
    return (
      <div className="text-center p-b-8">
        <span className="text-blue font-bold p-v-16 font-25">Publicación no encontrada 404</span>
      </div>
    );
  }

  return (
    <Layout
      facebookLink={facebookLink}
      instagramLink={instagramLink}
      logo={club.logo}
      menu={menus}
      rootPath={club.route ? `../${club.route}` : '/'}
      twitterLink={twitterLink}
      whiteTheme
    >
      <>
        <ImgBannerContainer img={ImgBanner}>
          <>
            <h1 className="text-center p-b-16">Publicaciones</h1>

            <h2 className="text-white text-center font-light">{`Home//${post ? post.title : ''}`}</h2>
          </>
        </ImgBannerContainer>

        <div className="container flex-col">
          <Img src={post.img} className="post-image" />

          {postId ? renderContent() : renderNoContent()}
        </div>
      </>
    </Layout>
  );
};

export default PostPage;
